/**
 * Absolute Background Cover
 */
.absolute-bg {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

/**
 * Color Helpers
 */
.bg-black {
  background-color: $color-black;
}

.bg-white {
  background-color: $color-white;
}
