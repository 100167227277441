/**
 * Tabs
 * On the Blog page for posts and categories
 */
.tabs {
  @extend %letter-spacing;

  display: flex;
  justify-content: space-around;
  padding: 1em;

  @media (min-width: $mq-md) {
    padding-left: 8em;
    padding-right: 8em;
  }

  @media (min-width: $mq-xxl) {
    padding-left: 10em;
    padding-right: 10em;
  }
  
  &__item {
    cursor: pointer;

    &:not(.active) {
      @include border-slide();
    }

    &.active {
      border-bottom: 3px solid $color-alpha;
    }
  }
}

.tab {

  &.active {
    will-change: opacity;
    animation: fade-in 1s both;
  }
}

/**
 * Blog Post Preview
 * On the Blog page
 */
.previews {

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 2em;

    color: $color-white;

    span {
      @extend %letter-spacing;

      font-family: $font-body;
    }
  }
}

.preview {
  transition: background-color 0.2s ease-in-out;
  
  &:nth-child(even) {
    background-color: $color-white;
  }

  &:hover {
    background-color: $color-alpha--light;

    .preview__more {
      box-shadow: inset 175px 0 0 0 $color-alpha;
      color: $color-white;
    }
  }

  &__img {
    will-change: opacity;
    animation: fade-in 1s both;
  }

  &__link {
    display: block;
    padding: 2em 1em;

    @media (min-width: $mq-md) {
      padding: 4em 8em;
    }

    @media (min-width: $mq-xxl) {
      padding-left: 10em;
      padding-right: 10em;
    }
  }

  &__date {
    font-family: $font-header;
    color: $color-black;
  }

  &__header {
    color: $color-alpha;

    margin-bottom: 0.5em;
  }

  &__excerpt {
    color: $color-black;
  }

  &__more {
    @include button-slide();
  }
}

/**
 * Category Card
 * On the blog page under category tab
 */
.cards {
  @include flex-grid(1, 2, $xxl: 3, $pad: 0.25em);

  padding: 2em 1em;

  @media (min-width: $mq-md) {
    padding: 4em 2em;
  }
}

.card {

  &__link {
    @include box-shadow(1px);

    position: relative;

    display: block;

    background-color: $color-white;
    transition: background-color 0.2s ease-in-out;

    &:after {
      @include box-shadow(3px);

      content: '';
      position: absolute;
      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      opacity: 0;
      will-change: opacity;
      transition: opacity 0.4s ease-in-out;
    }

    &:hover {
      background-color: $color-alpha--light;

      &:after {
        opacity: 1;
      }
      
      .card__more {
        box-shadow: inset 175px 0 0 0 $color-alpha;
        color: $color-white;
      }
    }
  }

  &__img {
    position: relative;

    height: 12em;

    background-color: $color-black;

    &:after {
      @extend %overlay;
    }
  }

  &__container {
    padding: 2em 1em;
  }

  &__header {
    color: $color-alpha;
  }

  &__count {
    color: $color-black;
  }

  &__more {
    @include button-slide(0.25em);
  }
}

/**
 * Pagination
 * On the Blog page when enough posts to paginate
 */
.pagination {
  @extend %letter-spacing;

  display: flex;
  width: 100%;

  & > * {
    display: flex;
    justify-content: center;
    flex: 1;

    padding: 2em 0;

    color: $color-white;
    background-color: $color-black;
    transition: background-color 0.4s ease-in-out;

    &:hover {
      color: $color-white;
      background-color: $color-alpha;
    }
  }
}

/**
 * Footer
 */
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;

  @media (min-width: $mq-sm) {
    flex-direction: row;
  }

  &__archive, &__links {
    @include border-slide();

    @extend %letter-spacing;
  }

  &__social {
    display: flex;
    margin-top: 1em;

    @media (min-width: $mq-sm) {
      margin-top: 0;
    }

    & > *:not(:last-child) {
      margin-right: 1em;
    }    
  }
}

/**
 * Mast
 * On the Post page for the header section
 */
.mast {
  position: relative;

  height: 35em;

  color: $color-white;
  background-color: $color-black;

  &:after {
    @extend %overlay;
  }

  &__img {
    animation: fade-in 2s both;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 90%;
    margin: 0 auto;
  }

  h1 {
    line-height: 1.2;
    text-shadow: 2px 2px black;
  }
}

/**
 * Navigation
 */
.nav {
  @extend %letter-spacing;

  position: absolute;
  top: 1.25em;
  left: 1em;
  z-index: 99;

  display: flex;
  align-items: center;

  font-family: $font-body;

  @media (min-width: $mq-sm) {
    left: 2em;
  }

  i {

    @media (min-width: $mq-sm) {
      margin-right: 0.5em;
    }
  }

  span {
    display: none;

    @media (min-width: $mq-sm) {
      display: block;
    }
  }

  &--black {
    color: $color-black;

    &:hover,
    &:active,
    &:focus {
      color: $color-black;
    }
  }

  &--white {
    color: $color-white;

    &:hover,
    &:active,
    &:focus {
      color: $color-white;
    }
  }
}

/**
 * Post
 */
.post {
  width: 90%;
  max-width: $mq-md;
  margin: 0 auto;

  font-family: $font-post;

  @media (min-width: $mq-md) {
    width: 55%;
  }

  h1, 
  h2 {
    line-height: 1.2;
  }


  a {
    border-bottom: 1px solid $color-alpha;
  }

  img {
    padding-bottom: 0.75em;
    filter: drop-shadow(2px 6px 4px #0006);
  }

  table {
    img {
       padding-bottom: 6px;
    }
    padding-bottom: 1em;
  }

  figure {
    padding-bottom: 0.75em;
  }

  figure > img {
    padding-bottom: 0;
  }
}

/* Timeline Post */
.post.timeline {
  width: 90%;
  max-width: 90em;

  @media (min-width: 90em) {
    width: 80%;
  }
}

/**
 * Profile Card
 */
.profile {
  width: 90%;
  max-width: $mq-md;
  margin: 0 auto;
  padding-bottom: 4em;

  @media (min-width: $mq-lg) {
    width: 55%;
  }

  &__card {
    @include box-shadow(1px);

    display: flex;
    flex-direction: column;

    @media (min-width: $mq-md) {
      flex-direction: row;
    }
  }

  &__img {
    position: relative;

    min-height: 15em;

    background-color: $color-black;

    @media (min-width: $mq-md) {
      flex-basis: 45%;
      max-width: 45%;
    }

    &:after {
      @extend %overlay;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;

    background-color: $color-white;

    @media (min-width: $mq-md) {
      flex-basis: 55%;
      max-width: 55%;
    }
  }

  &__social {
    display: flex;
    justify-content: flex-end;

    & > *:not(:last-child) {
      margin-right: 1em;
    }
  }
}

/**
 * Next Post
 * On the Post page for single post pagination
 */
.post-navigation {
  background-color: $color-black;
  margin: 0;
  position: relative;

  .next,
  .previous {
    width: 50%;
  
    &__link {
      display: block;
      height: 15em;
      padding: 3em 0;

      color: $color-white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;

      &:after {
        @extend %overlay;
      }

      &:hover {
        color: $color-white;
      }

      &:hover:after {
        background-color: rgba($color-alpha, 0.8);
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      text-align: center;

      span {
        @extend %letter-spacing;

        font-family: $font-body;
      }
    }
  }

  .next {
    float: left;
  }
  .previous {
    float: right;
  }
  .full-width {
    width: 100%;
  }
}

/**
 * Archives Page
 * Should be layout, but more elements added
 */
.archives {

  &__header {
    @extend %letter-spacing;

    padding: 1em;

    text-align: center;

    span {
      border-bottom: 3px solid $color-alpha;
    }
  }

  &__container {
    @include flex-grid(1, 2, 3, $xxl: 4, $pad: 0.25em);

    max-width: $mq-xl;
    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;

    text-shadow: 1px 1px black;
  }
}
