/**
 * Base Variables
 */
@import 'https://fonts.googleapis.com/css?family=Noto+Sans|Oswald';

$font-header: 'Oswald', sans-serif;
$font-body: 'Noto Sans', sans-serif;
$font-post: 'Noto Sans', sans-serif;

$color-alpha: #9f8f68;
$color-alpha--light: #f5f3ef;

$color-black: #0a0a0a;
$color-grey: #f8f9f9;
$color-white: #fff;

$mq-xs: 20em;
$mq-sm: 30em;
$mq-md: 54em;
$mq-lg: 64em;
$mq-xl: 76.5em;
$mq-xxl: 114em;

/**
 * Base Settings/Overwrite Normalize
 */
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: $font-body;
  font-feature-settings: 'liga' 0;
  font-size: 100%;
  line-height: 1.6;

  @media (min-width: $mq-xs) {
    font-size: 102.5%;
  }

  @media (min-width: $mq-sm) {
    font-size: 105%;
  }

  @media (min-width: $mq-md) {
    font-size: 107.5%;
  }

  @media (min-width: $mq-lg) {
    font-size: 110%;
  }

  @media (min-width: $mq-xl) {
    font-size: 115%;
  }

  @media (min-width: $mq-xxl) {
    font-size: 125%;
  }
}

h1, h2 {
  margin: 0;

  margin-top: 24px;
  margin-bottom: 16px;

  padding-bottom: 0.3em;
  border-bottom: 1px solid #eee;

  font-family: $font-header;
}

h1 {
  $fontSize: 1.5em;

  @media (min-width: $mq-md) {
    font-size: $fontSize;
  }

  @media (min-width: $mq-xl) {
    font-size: $fontSize + 0.5em;
  }
}

p {
  margin: 0 0 0.75em;
}

a {
  color: $color-black;
  text-decoration: none;
  transition: color 0.4s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $color-alpha;
  }
}

.post a {
  color: #108ee9;

  &:hover,
  &:active,
  &:focus {
    color: #49a9ee;;
  }
}

figure {
  margin: 0;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

ol, ul {
  padding: 0;
  list-style-type: none;
}

.post ol li {
  margin: 0;
  padding: 0 0 0 1em;
  list-style-type: decimal;
}

.post ol li ol li{
  margin: 0;
  padding: 0 0 0 1em;
  list-style-type: lower-roman;
}

.post ol li ol li ol li{
  margin: 0;
  padding: 0 0 0 1em;
  list-style-type: lower-alpha;
}

.post ul li {
  margin: 0;
  padding: 0 0 0 .4em;
  list-style-type: disc;
}

.post ul li ul li {
  margin: 0;
  padding: 0 0 0 .4em;
  list-style-type: circle;
}

.post ul li ul li ul li {
  margin: 0;
  padding: 0 0 0 .4em;
  list-style-type: square;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}


code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  /* Sean 2022-08-06 */
  padding: 2px 4px;
  font-size: 90%;
  background-color: #e8e8e8;
  border-radius: 4px;
}
kbd {
  /* Sean 2022-08-06 */
  padding: 4px 6px;
  font-size: 90%;
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 -1px 0 #ccc;
          box-shadow: inset 0 -1px 0 #ccc;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  -webkit-box-shadow: none;
          box-shadow: none;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre;
  background-color: transparent;
  border-radius: 0;
}


blockquote {
  padding: 0 1em;
  color: #777;
  border-left: 0.25em solid #ddd;
}


/**
 * Text Selection
 */
::selection {
  color: $color-white;
  background-color: $color-alpha;
  text-shadow: none;
}
